import React, {useEffect} from 'react';

const TermosDeServico = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="p-4 md:p-8 lg:p-12 pt-24 md:pt-0 bg-gray-100 text-gray-700">
            <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-6 md:p-12">
                <h1 className="text-2xl md:text-4xl font-bold mb-6 text-[#126232] text-center">
                    Termos de Serviço
                </h1>
                <p className="text-sm md:text-base text-gray-500 text-center mb-6">Última atualização: 2024</p>

                <p className="mb-6">
                    Bem-vindo ao site da <strong>Protege Piso</strong>! Ao acessar e utilizar este site, você concorda com os seguintes Termos de Serviço. Por favor, leia com atenção.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">1. Aceitação dos Termos</h2>
                <p className="mb-6">
                    Ao utilizar o site da Protege Piso, você concorda em cumprir e respeitar todos os termos e condições estabelecidos neste documento. Caso você não concorde com estes Termos de Serviço, por favor, não utilize o site.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">2. Alterações nos Termos</h2>
                <p className="mb-6">
                    A Protege Piso reserva-se o direito de atualizar ou modificar estes Termos de Serviço a qualquer momento, sem aviso prévio. É sua responsabilidade verificar regularmente esta página para manter-se informado sobre eventuais mudanças. Seu uso contínuo do site após a publicação de alterações constituirá sua aceitação das mudanças.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">3. Uso do Site</h2>
                <p className="mb-6">
                    Você concorda em utilizar o site apenas para finalidades legais e de acordo com a legislação vigente. Você está proibido de:
                </p>
                <ul className="list-disc pl-6 mb-6">
                    <li>Enviar ou transmitir qualquer material que seja ilegal, fraudulento, difamatório, obsceno ou ofensivo.</li>
                    <li>Tentar obter acesso não autorizado a qualquer parte do site ou aos servidores da Protege Piso.</li>
                    <li>Utilizar qualquer dispositivo, software ou rotina para interferir no funcionamento adequado do site.</li>
                </ul>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">4. Propriedade Intelectual</h2>
                <p className="mb-6">
                    Todo o conteúdo presente neste site, incluindo, mas não se limitando a textos, imagens, gráficos, logotipos e design, é de propriedade da Protege Piso ou de seus licenciadores, e está protegido por leis de direitos autorais e outras leis de propriedade intelectual. Você não pode reproduzir, distribuir, modificar ou criar trabalhos derivados sem a permissão expressa da Protege Piso.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">5. Links para Terceiros</h2>
                <p className="mb-6">
                    Nosso site pode conter links para outros sites que não são operados pela Protege Piso. Esses links são fornecidos para sua conveniência, e não implicam em aprovação ou garantia sobre o conteúdo dos sites de terceiros. A Protege Piso não é responsável pelas práticas de privacidade ou pelo conteúdo de qualquer site de terceiros.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">6. Isenção de Garantias</h2>
                <p className="mb-6">
                    O uso deste site é por sua conta e risco. A Protege Piso não garante que o site estará sempre disponível ou que estará livre de erros ou omissões. O conteúdo do site é fornecido “no estado em que se encontra”, sem garantias de qualquer tipo, expressas ou implícitas.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">7. Limitação de Responsabilidade</h2>
                <p className="mb-6">
                    Em nenhum caso a Protege Piso será responsável por quaisquer danos diretos, indiretos, incidentais, especiais, punitivos ou consequenciais que resultem do uso ou da incapacidade de uso do site, mesmo que a Protege Piso tenha sido avisada da possibilidade de tais danos.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">8. Indenização</h2>
                <p className="mb-6">
                    Você concorda em indenizar e isentar a Protege Piso, seus diretores, funcionários e agentes, de quaisquer reclamações, responsabilidades, danos e despesas, incluindo honorários advocatícios, decorrentes do seu uso do site ou da violação destes Termos de Serviço.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">9. Lei Aplicável</h2>
                <p className="mb-6">
                    Estes Termos de Serviço serão regidos e interpretados de acordo com as leis do Brasil, sem considerar seus conflitos de disposições legais. Qualquer disputa resultante do uso do site será resolvida nos tribunais competentes do Brasil.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">10. Contato</h2>
                <p>
                    Se você tiver alguma dúvida sobre estes Termos de Serviço, entre em contato conosco.
                </p>
            </div>
        </div>
    );
};

export default TermosDeServico;
