import React from 'react';
import Mapa from "./Mapa";

function Unidades(props) {
    return (
        <div className="pt-24 md:pt-0">
            <div>
                <h2 className="flex align-center justify-center mt-12 mb-6 text-2xl md:text-3xl font-bold text-[#17A541]">Encontre a unidade mais próxima de você!</h2>
            </div>
            <Mapa/>
        </div>
    );
}

export default Unidades;