import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const navigate = useNavigate();

    const handleNav = () => {
        setNav(!nav);
    };

    const scrollToSection = (sectionId) => {
        if (window.location.pathname === '/') {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            navigate('/');
            setTimeout(() => {
                const section = document.getElementById(sectionId);
                if (section) {
                    section.scrollIntoView({ behavior: 'smooth' });
                }
            }, 300);
        }
        setNav(false);
    };

    return (
        <div className='w-full top-0 z-50 md:relative fixed'>
            <div className='flex justify-between items-center h-24 bg-[#17A541] text-white font-sans'>
                <div className='md:hidden cursor-pointer z-50 absolute left-4 top-4 flex items-center'>
                    <div onClick={handleNav}>
                        {nav ? <AiOutlineClose size={40} color="white" /> : <AiOutlineMenu size={40} color="white" />}
                    </div>
                    <div className='ml-4 text-2xl font-bold text-white md:hidden'>PROTEGE PISO</div>
                </div>

                <ul className='hidden md:flex flex-1 justify-start md:justify-between font-bold uppercase text-sm'>
                    <div className='flex space-x-4'>
                        <Link to="/" className='p-4 hover:text-black cursor-pointer ml-24'>Home</Link>
                        <button onClick={() => scrollToSection('payuse')} className='p-4 pb-2 text-sm hover:text-black cursor-pointer uppercase '>Pay-Use</button>
                        <button onClick={() => scrollToSection('diferencial')} className='p-4 pb-2 text-sm hover:text-black cursor-pointer uppercase'>Diferencial</button>
                        <button onClick={() => scrollToSection('videos')} className='p-4 pb-2 text-sm hover:text-black cursor-pointer uppercase'>Vídeos</button>
                        <button onClick={() => scrollToSection('fotos')} className='p-4 pb-2 text-sm hover:text-black cursor-pointer uppercase'>Fotos</button>
                        <Link to="/folder" className='p-4 hover:text-black cursor-pointer uppercase'>Folder</Link>
                        <Link to="/unidades" className='p-4 hover:text-black cursor-pointer uppercase'>Unidades</Link>
                        <a href="https://webmail-seguro.com.br/" target="_blank" rel="noopener noreferrer" className='p-4 hover:text-black cursor-pointer uppercase'>Email</a>
                    </div>
                    <div className='flex items-center mr-24'>
                        <Link to="/franqueado" className='p-2 text-white border-2 border-white hover:border-black tracking-widest hover:text-black cursor-pointer'>
                            SEJA NOSSO FRANQUEADO
                        </Link>
                    </div>
                </ul>
            </div>

            <ul className={`absolute top-24 left-0 w-[100%] bg-[#17A541] text-center border-r border-r-white transform ${nav ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-500 ease-in-out z-40 flex flex-col`}>
                <button onClick={() => scrollToSection('home')} className='p-4 border-b border-white text-white uppercase'>Home</button>
                <button onClick={() => scrollToSection('payuse')} className='p-4 border-b border-white text-white uppercase'>Pay-use</button>
                <button onClick={() => scrollToSection('diferencial')} className='p-4 border-b border-white text-white uppercase'>Diferencial</button>
                <button onClick={() => scrollToSection('videos')} className='p-4 border-b border-white text-white uppercase'>Vídeos</button>
                <button onClick={() => scrollToSection('fotos')} className='p-4 border-b border-white text-white uppercase'>Fotos</button>
                <Link to="/folder" className='p-4 border-b border-white text-white uppercase'>Folder</Link>
                <Link to="/unidades" className='p-4 border-b border-white text-white uppercase'>Unidades</Link>
                {/* Corrigindo o rel="noopener noreferrer" */}
                <a href="https://mail.hostinger.com/" target="_blank" rel="noopener noreferrer" className='p-4 text-white hover:text-black cursor-pointer uppercase'>Email</a>
                <Link to="/franqueado" className='w-full text-3xl font-bold text-white p-4 uppercase'>SEJA NOSSO FRANQUEADO</Link>
            </ul>
        </div>
    );
};

export default Navbar;
