import './App.css';
import React from 'react'
import NavBar from './components/NavBar';
import {BrowserRouter, BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./Home";
import Franqueado from "./components/Franqueado";
import Folder from "./components/Folder";
import JivoChat from "./components/JivoChat";
import Unidades from "./components/Unidades";
import TermosDeServico from "./components/TermosDeServico";
import PoliticasDePrivacidade from "./components/PoliticasDePrivacidade";

function App() {
  return (
      <BrowserRouter>
        <NavBar/>
          <JivoChat/>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/franqueado" element={<Franqueado/>}/>
            <Route path="/folder" element={<Folder/>}/>
            <Route path="/unidades" element={<Unidades/>}/>
            <Route path="/termosdeservico" element={<TermosDeServico/>}/>
            <Route path="/politicadeprivacidade" element={<PoliticasDePrivacidade/>}/>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
