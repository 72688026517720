import React, {useEffect} from 'react';

const PoliticasDePrivacidade = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="p-4 pt-24 md:p-8 lg:p-12 bg-white text-gray-700">
            <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-6 md:p-12">
                <h1 className="text-2xl md:text-4xl font-bold mb-6 text-[#126232] text-center">
                    Políticas de Privacidade
                </h1>
                <p className="text-sm md:text-base text-gray-500 text-center mb-6">Última atualização: 2024</p>

                <p className="mb-6">
                    Na <strong>Protege Piso</strong>, a sua privacidade é nossa prioridade. Este documento explica como coletamos, utilizamos e protegemos suas informações pessoais ao acessar e utilizar nosso site.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">1. Informações Coletadas</h2>
                <p className="mb-6">
                    Nós coletamos diferentes tipos de informações com a finalidade de melhorar nossos serviços e sua experiência no site:
                </p>
                <p className="font-semibold mb-2">a) Informações fornecidas por você</p>
                <p className="mb-6">
                    Ao interagir com nosso site, você pode nos fornecer informações pessoais, como:
                </p>
                <ul className="list-disc pl-6 mb-6">
                    <li>Nome</li>
                    <li>Endereço de e-mail</li>
                    <li>Telefone</li>
                    <li>Endereço físico</li>
                    <li>Outras informações voluntariamente fornecidas em formulários de contato ou cadastro.</li>
                </ul>

                <p className="font-semibold mb-2">b) Informações automáticas</p>
                <p className="mb-6">
                    Nós também coletamos informações automaticamente quando você navega em nosso site, como:
                </p>
                <ul className="list-disc pl-6 mb-6">
                    <li>Endereço IP</li>
                    <li>Tipo de navegador</li>
                    <li>Páginas visitadas</li>
                    <li>Tempo de navegação</li>
                    <li>Informações de dispositivos móveis (se aplicável)</li>
                </ul>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">2. Uso das Informações</h2>
                <p className="mb-6">
                    As informações que coletamos podem ser utilizadas das seguintes formas:
                </p>
                <ul className="list-disc pl-6 mb-6">
                    <li>Para melhorar a navegação e funcionalidade do site.</li>
                    <li>Para responder a suas solicitações de atendimento ao cliente.</li>
                    <li>Para enviar atualizações, promoções e outras informações de marketing, desde que você tenha optado por recebê-las.</li>
                    <li>Para cumprir obrigações legais ou proteger os direitos da Protege Piso e de seus usuários.</li>
                </ul>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">3. Compartilhamento de Informações</h2>
                <p className="mb-6">
                    Não vendemos, alugamos ou trocamos suas informações pessoais com terceiros. No entanto, podemos compartilhar suas informações com:
                </p>
                <ul className="list-disc pl-6 mb-6">
                    <li>
                        <strong>Fornecedores de serviços</strong>: Terceiros que prestam serviços em nosso nome, como provedores de hospedagem, ferramentas de análise e marketing, e suporte ao cliente.
                    </li>
                    <li>
                        <strong>Autoridades legais</strong>: Quando necessário para cumprir a lei ou responder a solicitações legais.
                    </li>
                    <li>
                        <strong>Parceiros de negócios</strong>: Com o seu consentimento, poderemos compartilhar informações com parceiros para fins específicos.
                    </li>
                </ul>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">4. Cookies</h2>
                <p className="mb-6">
                    Nosso site utiliza cookies e tecnologias similares para melhorar sua experiência de navegação. Cookies são pequenos arquivos de texto armazenados em seu dispositivo que nos permitem reconhecê-lo e lembrar de suas preferências.
                </p>
                <p className="font-semibold mb-2">Tipos de cookies que usamos:</p>
                <ul className="list-disc pl-6 mb-6">
                    <li><strong>Cookies estritamente necessários</strong>: Essenciais para o funcionamento do site.</li>
                    <li><strong>Cookies de desempenho</strong>: Coletam informações sobre como os usuários utilizam o site.</li>
                    <li><strong>Cookies de funcionalidade</strong>: Lembram suas preferências para oferecer uma experiência personalizada.</li>
                </ul>

                <p className="mb-6">
                    Você pode optar por desativar os cookies através das configurações do seu navegador, mas isso pode afetar a funcionalidade do site.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">5. Segurança das Informações</h2>
                <p className="mb-6">
                    A Protege Piso adota medidas de segurança apropriadas para proteger suas informações contra acesso não autorizado, perda, alteração ou destruição. No entanto, nenhuma transmissão de dados pela internet é completamente segura, e não podemos garantir a segurança absoluta das informações transmitidas online.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">6. Retenção de Dados</h2>
                <p className="mb-6">
                    As informações coletadas serão armazenadas pelo tempo necessário para cumprir as finalidades descritas nesta Política de Privacidade, exceto quando um período de retenção maior for exigido ou permitido por lei.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">7. Seus Direitos</h2>
                <p className="mb-6">
                    Você tem o direito de:
                </p>
                <ul className="list-disc pl-6 mb-6">
                    <li>Acessar suas informações pessoais e solicitar cópias de dados armazenados.</li>
                    <li>Corrigir ou atualizar informações incorretas ou incompletas.</li>
                    <li>Solicitar a exclusão de suas informações, exceto quando houver necessidade legal de retê-las.</li>
                    <li>Cancelar o consentimento para o uso de suas informações para fins de marketing.</li>
                </ul>

                <p className="mb-6">
                    Para exercer seus direitos, entre em contato conosco através das informações de contato fornecidas abaixo.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">8. Alterações na Política de Privacidade</h2>
                <p className="mb-6">
                    Podemos atualizar esta Política de Privacidade periodicamente. Qualquer alteração será publicada nesta página com a data da última atualização. Recomendamos que você reveja esta política regularmente para se manter informado sobre como protegemos suas informações.
                </p>

                <h2 className="text-xl md:text-2xl font-semibold mb-4">9. Contato</h2>
                <p>
                    Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade ou sobre o uso de suas informações pessoais, entre em contato conosco.
                </p>
            </div>
        </div>
    );
};

export default PoliticasDePrivacidade;
