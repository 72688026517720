import { useEffect } from 'react';

const JivoChat = () => {
    useEffect(() => {

        const jivoChatScript = document.createElement('script');
        jivoChatScript.src = 'https://code.jivosite.com/widget/pAu0aDYLtr';
        jivoChatScript.async = true;

        jivoChatScript.onload = () => {
            // Verifica se a API do JivoChat está disponível corretamente
            if (window.jivo_api && typeof window.jivo_api.open === 'function') {
                console.log('jivochat-ok.');
            } else {
                console.log("jivochat-notok");
            }
        };

        document.body.appendChild(jivoChatScript);

        return () => {
            document.body.removeChild(jivoChatScript);
        };
    }, []);

    return null;
};

export default JivoChat;
