import React from 'react';
import '../App.css'

const BannerHome = () => {
    return (
        <div className='flex flex-col lg:flex-row relative h-auto lg:h-80 pt-24 md:pt-0'>
            <div className='relative w-full lg:w-[58%] h-64 lg:h-auto bg-cover bg-center'
                 style={{backgroundImage: 'url(foto01.jpg)'}}>
                <img
                    src='https://protegepiso.com.br/wp-content/uploads/elementor/thumbs/logo-contorno-final-3-qr9ktubyh3wykeoagoppheagkansuuyyyeqvz95qtk.png'
                    alt='Logo'
                    className='absolute top-5 left-4 p-4 sm:p-[6%] pl-[25%] sm:pl-[35%]'
                />
            </div>
            <div className='flex flex-col justify-center p-4 sm:p-8 w-full lg:w-[42%] text-white bg-[#17A541]'>
                <div className='text-center lg:text-right lg:pr-16'>
                    <h1 className='font-bold text-2xl sm:text-3xl lg:text-4xl mb-4'>
                        Existe uma solução definitiva para proteção do seu piso durante sua obra ou reforma.
                    </h1>
                    <p className='text-lg sm:text-xl'>
                        CONHEÇA A PROTEGE PISO E FIQUE LONGE DE RISCOS
                    </p>
                </div>
            </div>
        </div>


    );
};

export default BannerHome;
